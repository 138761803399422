import isEmpty from "lodash/isEmpty";
import React, { ImgHTMLAttributes, useEffect, useState } from "react";

import placeholder from "~/assets/images/placeholder.png";
import Image from "~/components/atoms/Image";
import { IMediaImageUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";

export interface ImageResponsiveProps
  extends ImgHTMLAttributes<HTMLImageElement> {
  alt?: string;
  className?: string;
  imageData?: IMediaImageUrls;
  maxWidth?: number;
  zoom?: number;
}

const getZoomClass = (zoomLevel: number) => {
  // Map zoom level to Tailwind CSS scale classes
  const zoomClasses: { [key: number]: string } = {
    1: "scale-[1]",
    1.3: "scale-[1.3]",
    2: "scale-[2]",
    3: "scale-[3]",
  };

  // Get the closest match in zoomClasses or default to 'scale-[1]'
  if (zoomLevel <= 1) return zoomClasses[1];
  if (zoomLevel === 1.3) return zoomClasses[1.3];
  if (zoomLevel <= 2 && zoomLevel !== 1.3) return zoomClasses[2];
  return zoomClasses[3];
};

const ImageResponsive: React.FC<ImageResponsiveProps> = ({
  alt = "",
  className = "",
  imageData,
  maxWidth,
  zoom = 1, // Default zoom level to 1 if undefined
  ...props
}) => {
  const isEmptyImageData =
    isEmpty(imageData) ||
    (!isEmpty(imageData) &&
      (!Array.isArray(imageData?.urls) || imageData?.urls?.length === 0));

  const [zoomClass, setZoomClass] = useState(getZoomClass(zoom));

  useEffect(() => {
    setZoomClass(getZoomClass(zoom));
  }, [zoom]);

  if (isEmptyImageData)
    return (
      <Image
        isLocalImage
        url={placeholder}
        className={cn("h-full w-full", className)}
      />
    );

  const filteredUrls = imageData?.urls?.filter(
    (image) => !maxWidth || image.width <= maxWidth
  );
  const altText = `Image for ${imageData.relation} ${alt}`;

  return (
    <picture className={cn("h-full w-full", className)}>
      {filteredUrls.map((image, index) => (
        <source
          key={index}
          srcSet={ENV.IMAGE_DOMAIN_URL + image?.url}
          media={`(min-width: ${(image?.width || 0) / 2}px)`}
        />
      ))}
      <img
        className={cn(
          "h-auto w-auto transform object-cover transition-transform duration-300",
          className,
          zoomClass
        )}
        src={ENV.IMAGE_DOMAIN_URL + filteredUrls[filteredUrls.length - 1]?.url}
        alt={altText}
        loading="lazy"
        {...props}
      />
    </picture>
  );
};

export default ImageResponsive;
